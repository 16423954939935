'use client';

// ESRI Maps
// import '@arcgis/core/assets/esri/themes/light/main.css';
// import '@esri/calcite-components/dist/calcite/calcite.css';

// Bootstrap css
import '../../styles/libraryStyleOverrides/bootstrap.scss';

// Fontawesome icons (add all at once vs. individually)
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

// Date picker
import 'react-datepicker/dist/react-datepicker.css';

// Json view for debug sections
import 'react-json-view-lite/dist/index.css';

// Overrides

// TODO: move some of these into the bootstrap overrides as variables
import '../../styles/libraryStyleOverrides/Button.scss';
import '../../styles/libraryStyleOverrides/Input.scss';
import '../../styles/libraryStyleOverrides/Modal.scss';
import '../../styles/libraryStyleOverrides/Tabs.scss';

import '../../styles/libraryStyleOverrides/DatePicker.scss';

// Other global styles
import './global.scss';

// Use CDN to try to lower memory consumption for builds
function ArcGISStyles() {
	return (
		<>
			{/* { Calcite Design System */}
			<link
				rel="stylesheet"
				href="https://js.arcgis.com/calcite-components/2.13.2/calcite.css"
				crossOrigin="anonymous"
			/>

			{/* ArcGIS Core */}
			<link
				rel="stylesheet"
				href="https://js.arcgis.com/4.31/esri/themes/light/main.css"
				crossOrigin="anonymous"
			/>
		</>
	);
}

export default function GlobalStyles() {
	return (
		<>
			<ArcGISStyles />
		</>
	);
}
