'use client';

import dynamic from 'next/dynamic';
import type { ComponentType } from 'react';

// Only include the debugger when running locally
let DevOnlyJotaiDebugging: ComponentType = () => null;
if (process.env.NODE_ENV === 'development') {
	DevOnlyJotaiDebugging = dynamic(async () => import('./JotaiDebugging'), {
		ssr: false,
	});
}

export default DevOnlyJotaiDebugging;
